import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const TeamPage = () => (
  <Layout pageInfo={{ pageName: "team" }}>
    <Seo title="TrackSecure Team" keywords={[ `Hardi Probst`, `Jens Horvath`, `Tilmann Glaser`, `CMO`, `CPO`, `CTO`, `CFO` ]} />
    <h1 class="heading-4">Team</h1>

    <Container fluid>
      <Row>
        <Col>
          <div class="person-image"><StaticImage src="../images/team/jens.jpg" alt="Jens-Peter Horvath" placeholder="blurred"/></div><br/>
          <div class="person-name">Jens-Peter Horvath</div><br/>
          <div class="person-role">Geschäftsführer Finanzen</div><br/>
          <div class="person-about">Neben der Verantwortung für die Finanzen von TrackSecure habe ich die ersten technischen Schritte zur App begleitet und kümmere mich bis heute um die Implementierung. Dabei lege ich viel Wert auf einfache und spielerische Bedienung. Denn: TrackSecure soll nicht nur die Sicherheit erhöhen sondern auch Spaß machen.</div><br/>
        </Col>
        <Col>
          <div class="person-image"><StaticImage src="../images/team/hardi.jpg" alt="Hardi Probst" placeholder="blurred"/></div><br/>
          <div class="person-name">Hardi Probst</div><br/>
          <div class="person-role">Geschäftsführer Produktentwicklung & IT</div><br/>
          <div class="person-about">Es ist mir ein persönliches Anliegen den Motorsport und sportliche Fahrveranstaltungen mit TrackSecure sicherer zu machen.
          Vor TrackSecure habe ich als IT-Unternehmer & Gründer der it-economics GmbH (Verkauf an die Sopra-Steria SE),
          als freier IT-Berater, Solution Architect und Gründer/CTO der Contonomy AG gearbeitet.</div><br/>
        </Col>
        <Col>
          <div class="person-image"><StaticImage src="../images/team/tilmann.jpg" alt="Tilman Glaser" placeholder="blurred"/></div><br/>
          <div class="person-name">Tilmann Glaser</div><br/>
          <div class="person-role">Geschäftsführer Prozesse & Betrieb</div><br/>
          <div class="person-about">Für mich gehören Softwareentwicklung und -betrieb unweigerlich zusammen um Systeme nachhaltig erfolgreich zu machen. Daher freue ich mich, bei TrackSecure neben meiner Geschäftsführertätigkeit auch mit meiner Erfahrung als Software Entwickler und Software Architekt unterstützen zu können. Als leidenschaftlicher Motorradfahrer hat mich TrackSecure schon früh begeistert und ich bin froh, mittlerweile ein Teil des Teams zu sein.</div><br/>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default TeamPage
